<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Data Welder'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12" class="mb-2">
              <b-button variant="outline-dark" @click="$router.push('/kualifikasi_las/addwelder')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table head-variant="dark" bordered :items="items" :fields="fields" :current-page="currentPage"
                stacked="lg" show-empty responsive hover :no-local-sorting="true" @sort-changed="sortTable"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @filtered="onFiltered" @row-clicked="info"
                :busy="isBusy" v-model="currentItems">
                <template #thead-top="data">
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>
                      <b-form-input id="filter-1" size="sm" @input="loadData" v-model="search.nama_welder" type="search"
                        placeholder="Cari Nama Welder..."></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input id="filter-4" size="sm" @input="loadData" v-model="search.welder_id" type="search"
                        placeholder="Cari Welder ID..."></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input id="filter-2" size="sm" @input="loadData" v-model="search.tgl_lahir" type="search"
                        placeholder="Cari Tgl Lahir..."></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input id="filter-4" size="sm" @input="loadData" v-model="search.peusahaan" type="search"
                        placeholder="Cari Perusahaan..."></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input id="filter-3" size="sm" @input="loadData" v-model="search.alamat" type="search"
                        placeholder="Cari Alamat..."></b-form-input>
                    </b-th>
                    <b-th colspan="1"></b-th>
                    <b-th colspan="1"></b-th>
                  </b-tr>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(alamat)="data">
                  <span v-html="data.item.alamat"></span>
                </template>
                <template #cell(tgl_lahir)="data">
                  {{ data.item.tgl_lahir == null ? "" : moment().diff(data.item.tgl_lahir, 'years') }}
                </template>
                <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item-button @click="info(data.item, $event.target)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="$router.push('/kualifikasi_las/editwelder/' + data.item.id_welder)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="toDelete(data)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col md="5">
              <b-pagination v-model="currentPage" @input="paginate" :total-rows="totalRows" :per-page="10" align="fill"
                size="sm" class="my-0"></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
          <b-modal id="info-modal" size="lg" title="Detail" ok-only @hide="resetInfoModal">
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Nama Welder :</b></b-col>
              <b-col>{{ modalInfo.nama_welder }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Welder ID :</b></b-col>
              <b-col>{{ modalInfo.welder_id }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Tanggal Lahir :</b></b-col>
              <b-col>{{ modalInfo.tgl_lahir }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Alamat :</b></b-col>
              <b-col><span v-html="modalInfo.alamat"></span></b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Perusahaan :</b></b-col>
              <b-col>{{ modalInfo.perusahaan }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Rejection Rate :</b></b-col>
              <b-col>{{ `${modalInfo.rejection_rate} %` }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Foto :</b></b-col>
              <b-col sm="9">
                <b-img :src="urlStorage + '/images/welder/' + modalInfo.foto" fluid v-if="modalInfo.foto"></b-img>
                <span v-else>No Image</span>
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import * as moment from "moment";

export default {
  data() {
    return {
      sortBy: 'nama_welder',
      sortDesc: false,
      sort_label: 'ASC',
      items: [],
      fields: [
        { key: 'no', label: 'No' },
        { key: 'nama_welder', label: 'Nama Welder', sortable: true },
        { key: 'welder_id', label: 'Welder ID', sortable: true },
        {
          key: 'tgl_lahir',
          label: 'Umur',
          sortable: true,
        },
        { key: 'perusahaan', label: 'Perusahaan', sortable: true },
        { key: 'alamat', label: 'Alamat', sortable: true },
        { key: 'rejection_rate', label: 'Rejection Rate', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      currentItems: [],
      search: {
        nama_welder: '',
        welder_id: '',
        tgl_lahir: '',
        alamat: '',
        perusahaan: '',
      },
      modalInfo: {
        nama_welder: null,
        welder_id: null,
        tgl_lahir: null,
        alamat: null,
        perusahaan: null,
        rejection_rate: null,
        foto: null,
      },
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      isBusy: false,
      dialog: false,

      urlStorage: '',
    }
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kualifikasi Las', route: '' },
      { title: 'Welder' },
    ])
  },
  created() {
    var self = this
    self.urlStorage = ApiService.urlStorage()
    ApiService.get(
      '/kualifikasi_las/welder?page=' +
      this.currentPage +
      '&nama_welder=' +
      this.search.nama_welder +
      '&welder_id=' +
      this.search.welder_id +
      '&tgl_lahir=' +
      this.search.tgl_lahir +
      '&alamat=' +
      this.search.alamat +
      '&perusahaan=' +
      this.search.perusahaan +
      '&sortBy=' +
      this.sortBy +
      '&sortDesc=' +
      this.sort_label
    )
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.items = data.data.data
          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.isBusy = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    moment: function () {
      return moment();
    },
    info(item, button) {
      this.modalInfo.nama_welder = item.nama_welder
      this.modalInfo.welder_id = item.welder_id
      this.modalInfo.tgl_lahir = item.tgl_lahir
      this.modalInfo.alamat = item.alamat
      this.modalInfo.perusahaan = item.perusahaan
      this.modalInfo.rejection_rate = item.rejection_rate
      this.modalInfo.foto = item.foto
      this.$root.$emit('bv::show::modal', 'info-modal', button)
    },
    resetInfoModal() {
      this.modalInfo.nama_welder = null
      this.modalInfo.welder_id = null
      this.modalInfo.tgl_lahir = null
      this.modalInfo.alamat = null
      this.modalInfo.perusahaan = null
      this.modalInfo.rejection_rate = null
      this.modalInfo.foto = null
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get(
        '/kualifikasi_las/welder?page=' +
        this.currentPage +
        '&nama_welder=' +
        this.search.nama_welder +
        '&welder_id=' +
        this.search.welder_id +
        '&tgl_lahir=' +
        this.search.tgl_lahir +
        '&alamat=' +
        this.search.alamat +
        '&perusahaan=' +
        this.search.perusahaan +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.items = data.data.data
            self.totalPerPage = data.data.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    toDelete(row) {
      var id = row.item.id_welder
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/kualifikasi_las/welder/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                self.isBusy = true
                var interval1 = null
                var i = 0
                self.loadData()
                interval1 = setInterval(function () {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
